import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

type SubscriptionErrorDialogProps = {
  open: boolean;
  onClose: () => void;
};

const SubscriptionErrorDialog: React.FC<SubscriptionErrorDialogProps> = ({
  onClose,
  open,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="h4" color="secondary">
          <b>Houve um problema ao finalizar sua assinatura :(</b>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" className={classes.contentText}>
          Verifique se os dados de seu cartão estão corretos e se há limite
          disponível.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Entendi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionErrorDialog;

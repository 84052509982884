import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  subscriptionTypeSelectionStep: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 42,
    },
  },
  titleText: {
    fontWeight: 700,
    fontSize: 36,
    color: "rgba(87, 190, 191, 1)",
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
  descriptionText: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "25px",
    color: "rgba(124, 137, 153, 1)",
    fontFamily: "Roboto",
  },
  formArea: {
    flex: 1,
    display: "flex",
  },
  selectContainer: {
    width: "100%",
    display: "flex",
    gap: 10,
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  buttonsArea: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 10,
    },
  },
  button: {
    width: 186,
    height: 50,
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  returnButton: {
    backgroundColor: "#FFF !important",
    color: "rgba(87, 190, 191, 1)",
  },
  nextButton: {
    backgroundColor: "rgba(87, 190, 191, 1) !important",
    color: "#FFF",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  whatsAppButton: {
    position: "absolute",
    backgroundColor: "#25d366",
    color: "#FFF",
    zIndex: 2,
    right: 8,
    bottom: "5dvh",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#25d366",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "25dvh",
    },
  },
}));

export default useStyles;

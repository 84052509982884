import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  addressStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingBottom: 80,
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
      alignItems: "center",
    },
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginTop: 40,
    height: "100%",
  },
  form: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "85%",
    },
  },
  formArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  textInput: {
    marginBottom: 10,
    minHeight: 80,
    marginRight: 5,
    marginLeft: 5,
  },
  submitButtonArea: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 10,
      marginTop: 0,
    },
  },
  submitButton: {
    color: "#FFF",
    backgroundColor: "#57BEBF",
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
  returnButton: {
    backgroundColor: "#FFF !important",
    color: "#57BEBF",
    marginRight: 10,
  },
  button: {
    width: 186,
    height: 50,
    fontWeight: 700,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  row: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  loadingIndicator: {
    marginLeft: 8,
  },
  titleText: {
    color: "#57BEBF",
    fontWeight: 700,
    fontSize: 36,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
}));

export default useStyles;

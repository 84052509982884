import {
  CreateSubscriptionRequest,
  SubscriptionFormParams,
} from "../../../interfaces/subscription";

export function formatCreateSubscriptionRequestParams(
  subscription: SubscriptionFormParams
): CreateSubscriptionRequest {
  return {
    customer: {
      address: {
        cep: subscription.address.zipCode,
        city: subscription.address.city,
        complement: subscription.address.complement,
        neighborhood: subscription.address.neighborhood,
        number: subscription.address.number,
        state: subscription.address.state,
        street: subscription.address.street,
      },
      babyBirthDate: subscription.babyBirthDate,
      babyName: subscription.babyName,
      cpf: subscription.cpf,
      email: subscription.email,
      howFoundUs: subscription.howFoundUs,
      name: subscription.name,
      phoneNumber: subscription.phoneNumber,
    },
    payment: {
      cardToken: subscription.cardToken,
    },
    subscription: {
      box: subscription.selectedBoxes[0],
      type: subscription.type,
    },
  };
}

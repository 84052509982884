import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  babyInfoStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 20,
    },
  },
  stepInfoText: {
    marginTop: 10,
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "#7C8999",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  formContainer: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 40,
  },
  formArea: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  textInput: {
    marginBottom: 10,
    minHeight: 80,
  },
  submitButtonArea: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 10,
      marginTop: 0,
      marginBottom: 40
    },
  },
  titleText: {
    fontWeight: 700,
    fontSize: 36,
    color: "#57BEBF",
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
  form: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  button: {
    width: 186,
    height: 50,
    fontWeight: 700,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  submitButton: {
    backgroundColor: "#57BEBF",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
  },
  returnButton: {
    backgroundColor: "#FFF !important",
    color: "#57BEBF",
    marginRight: 10,
  },
}));

export default useStyles;

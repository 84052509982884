import {
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import React from "react";
import useStyles from "./style";

type ProcessSubscriptionDialogProps = {
  open: boolean;
};

const ProcessSubscriptionDialog: React.FC<ProcessSubscriptionDialogProps> = ({
  open,
}) => {
  const styles = useStyles();
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogContent className={styles.dialogContent}>
        <Typography variant="h5" color="secondary">
          <b>Estamos processando sua assinatura. Por favor, aguarde.</b>
        </Typography>
        <LinearProgress color="primary" className={styles.progress} />
      </DialogContent>
    </Dialog>
  );
};

export default ProcessSubscriptionDialog;

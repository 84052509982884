import { makeStyles } from "@material-ui/core";
import boxDetailsNavigatorBackground from "../../assets/images/box-details-navigator-background.png";

const useStyles = makeStyles((theme) => ({
  boxDetailsNavigator: {
    padding: "30px 0",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "blue",
    backgroundImage: `url(${boxDetailsNavigatorBackground})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  boxDetailsNavigatorWithoutText: {
    paddingTop: 0,
  },
  topArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleText: {
    fontWeight: 700,
    fontSize: 24,
    textTransform: "uppercase",
  },
  boxDetailsArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 50,
  },
  boxDetailText: {
    fontFamily: "Bakso Sapi",
    fontSize: 46,
    lineHeight: "46px",
  },
  navigationButtonsArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  navigationButton: {
    width: 100,
    height: 100,
    backgroundColor: "#FFF !important",
    color: "rgba(87, 190, 191, 1)",
  },
  navigationButtonLeft: {
    marginLeft: -50,
    paddingLeft: 35,
  },
  navigationButtonRight: {
    marginRight: -50,
    paddingRight: 50,
  },
  boxImage: {
    width: 296,
    height: 200,
    [theme.breakpoints.down("sm")]: {
      width: 250,
      height: 180,
    },
  },
  boxValuesArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkIcon: {
    width: 36,
    height: 36,
  },
  boxItemsContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 16,
  },
  boxItemsText: {
    fontWeight: 700,
    fontSize: 24,
    color: theme.palette.text.primary,
    textAlign: "center",
    marginLeft: 10,
  },
  boxPriceArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  boxPriceBaseText: {
    fontWeight: 800,
    fontSize: 24,
    color: theme.palette.text.primary,
  },
  boxPriceText: {
    fontWeight: 800,
    fontSize: 48,
    color: theme.palette.text.primary,
  },
  paymentDateText: {
    fontWeight: 700,
    fontSize: 20,
  },
  bottomDrawerTrigger: {
    width: "100%",
    height: 40,
    backgroundColor: "#F2AF2F",
  },
  expandMoreIcon: {
    color: "#FFF",
  },
}));

export default useStyles;

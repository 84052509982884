import {
  ButtonBase,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArrowBack as ArrowLeft,
  ArrowForward as ArrowRight,
  CheckCircle as CheckIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import basicBoxImage from "../../assets/images/basic-box-image.png";
import completeBoxImage from "../../assets/images/complete-box-image.png";
import plans, { PlanItem, PlanItemMonth } from "../../constants/plans";
import {
  SubscriptionTypeLabels,
  SubscriptionTypes,
} from "../../constants/subscription-types";
import useStyles from "./styles";

interface BoxDetailsNavigatorProps {
  selectedSubscriptionType: SubscriptionTypes;
  selectedMonth: number;
  onCloseDrawer?: Function;
}

function BoxDetailsNavigator(props: BoxDetailsNavigatorProps) {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeMonth, setActiveMonth] = useState(props.selectedMonth);
  const [activeBox, setActiveBox] = useState({} as PlanItemMonth);

  useEffect(() => {
    if (
      props.selectedMonth !== null &&
      typeof props.selectedMonth !== "undefined"
    ) {
      setActiveMonth(props.selectedMonth - 1);
    }
  }, [props]);

  useEffect(() => {
    setActiveBox(
      (plans[props.selectedSubscriptionType] as PlanItem).months[activeMonth]
    );
  }, [activeMonth, props.selectedSubscriptionType]);

  function onChangeActiveMonth(newActiveMonth: number): void {
    setActiveMonth(newActiveMonth);
  }

  return Object.keys(activeBox).length ? (
    <div
      className={classNames(
        classes.boxDetailsNavigator,
        typeof props.onCloseDrawer !== "undefined"
          ? classes.boxDetailsNavigatorWithoutText
          : null
      )}
    >
      {isMobile ? (
        <ButtonBase
          onClick={() => props.onCloseDrawer()}
          className={classes.bottomDrawerTrigger}
        >
          <ExpandMoreIcon className={classes.expandMoreIcon} />
        </ButtonBase>
      ) : null}
      <div className={classes.topArea}>
        <Typography className={classes.titleText}>
          O que vem em cada Box?
        </Typography>
        <div className={classes.boxDetailsArea}>
          <Typography className={classes.boxDetailText}>
            {SubscriptionTypeLabels[props.selectedSubscriptionType]}
          </Typography>
          <Typography className={classes.boxDetailText}>
            Box {activeMonth + 1}
          </Typography>
        </div>
      </div>
      <div className={classes.navigationButtonsArea}>
        <div>
          <IconButton
            className={classNames(
              classes.navigationButton,
              classes.navigationButtonLeft
            )}
            disabled={activeMonth === 0}
            onClick={() => onChangeActiveMonth(activeMonth - 1)}
          >
            <ArrowLeft />
          </IconButton>
        </div>
        <img
          src={
            props.selectedSubscriptionType === SubscriptionTypes.Basic
              ? basicBoxImage
              : completeBoxImage
          }
          className={classes.boxImage}
        />
        <div>
          <IconButton
            className={classNames(
              classes.navigationButton,
              classes.navigationButtonRight
            )}
            disabled={
              activeMonth ===
              plans[props.selectedSubscriptionType].months.length - 1
            }
            onClick={() => onChangeActiveMonth(activeMonth + 1)}
          >
            <ArrowRight />
          </IconButton>
        </div>
      </div>
      <div className={classes.boxValuesArea}>
        <div className={classes.boxItemsContainer}>
          <Typography className={classes.boxItemsText}>
            {activeBox.items.join("; ")}
          </Typography>
        </div>
        <div className={classes.boxPriceArea}>
          <Typography className={classes.boxPriceBaseText}>
            R${" "}
            <span className={classes.boxPriceText}>
              {activeBox.value?.subscription.toFixed(2).toString()}
            </span>{" "}
            /mês
          </Typography>
        </div>
      </div>
    </div>
  ) : null;
}

export default BoxDetailsNavigator;

function areAllValuesFilled(
  values: any,
  optionalFields: string[] = []
): boolean {
  return !Object.keys(values).some(
    (key) => !values[key] && !optionalFields.includes(key)
  );
}

export { areAllValuesFilled };

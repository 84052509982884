import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  signupProgressStepper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "16px 32px 0 32px",
    [theme.breakpoints.down("sm")]: {
      padding: 30,
    },
  },
  stepItem: {
    display: "flex",
    alignItems: "center",
    margin: "0 10px",
  },
  selectedStepItem: {
    flex: 1,
  },
  stepItemClickable: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  stepNumberContainer: {
    width: 40,
    height: 40,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepNumberText: {
    color: theme.palette.text.primary,
  },
  stepLabelText: {
    marginLeft: 10,
    color: "#7C8999",
    fontWeight: 700,
  },
}));

export default useStyles;

import {
  ButtonBase,
  Divider,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ExpandLess as ExpandLessIcon } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import EventTypes from "../../../constants/event-types";
import { PaymentTypes } from "../../../constants/payment-types";
import { SubscriptionTypes } from "../../../constants/subscription-types";
import eventService from "../../../services/event-service";
import BoxDetailsNavigator from "../../box-details-navigator";
import SignupForm from "../signup-form";
import SignupProgressStepper from "../signup-progress-stepper/SignupProgressStepper";
import useStyles from "./styles";

type SignupDialogProps = {
  selectedMonth: number;
  selectedPaymentType: PaymentTypes;
  termsAndConditionsAccepted: boolean;
};

function SignupDialog(props: SignupDialogProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    props.termsAndConditionsAccepted
  );
  const [bottomMenuOpen, setBottomMenuOpen] = useState(false);
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(
    SubscriptionTypes.Complete
  );

  useEffect(() => {
    eventService.addListener(
      EventTypes.TERMS_AND_CONDITIONS_CHANGED,
      (accepted: boolean) => {
        setTermsAndConditionsAccepted(accepted);
      }
    );

    eventService.addListener(
      EventTypes.SELECTED_SUBSCRIPTION_TYPE_CHANGED,
      (type: SubscriptionTypes) => {
        setSelectedSubscriptionType(type);
      }
    );

    return () => {
      eventService.removeListener(EventTypes.TERMS_AND_CONDITIONS_CHANGED, () =>
        setTermsAndConditionsAccepted(false)
      );

      eventService.removeListener(
        EventTypes.SELECTED_SUBSCRIPTION_TYPE_CHANGED,
        () => {
          setSelectedSubscriptionType(SubscriptionTypes.Complete);
        }
      );
    };
  }, []);

  return (
    <div className={classes.dialogContent}>
      {!isMobile ? (
        <div
          className={classNames(
            classes.leftContainer,
            !termsAndConditionsAccepted &&
              classes.leftContainerWithBackgroundImage
          )}
        >
          {termsAndConditionsAccepted ? (
            <BoxDetailsNavigator
              selectedSubscriptionType={selectedSubscriptionType}
              selectedMonth={props.selectedMonth}
            />
          ) : null}
        </div>
      ) : null}
      <div className={classes.rightArea}>
        {termsAndConditionsAccepted ? (
          <div className={classes.stepperArea}>
            <SignupProgressStepper selectedMonth={props.selectedMonth} />
          </div>
        ) : null}
        {termsAndConditionsAccepted ? (
          <Divider className={classes.divider} />
        ) : null}
        <div className={classes.formArea}>
          <SignupForm
            selectedPaymentType={props.selectedPaymentType}
            selectedSubscriptionType={selectedSubscriptionType}
          />
          {isMobile && termsAndConditionsAccepted ? (
            <>
              <ButtonBase
                onClick={() => setBottomMenuOpen(!bottomMenuOpen)}
                className={classes.bottomDrawerTrigger}
              >
                <Typography variant="h6">
                  <b>Veja o que vem em cada box</b>
                </Typography>
                <ExpandLessIcon className={classes.expandLessIcon} />
              </ButtonBase>
              <SwipeableDrawer
                anchor="bottom"
                open={bottomMenuOpen}
                onClose={() => setBottomMenuOpen(false)}
                onOpen={() => setBottomMenuOpen(true)}
                swipeAreaWidth={0}
                ModalProps={{
                  keepMounted: true,
                }}
                PaperProps={{
                  style: {
                    height: "100vh",
                  },
                }}
              >
                <div className={classes.bottomDrawerBoxDetailsComponent}>
                  <BoxDetailsNavigator
                    selectedSubscriptionType={selectedSubscriptionType}
                    selectedMonth={props.selectedMonth}
                    onCloseDrawer={() => setBottomMenuOpen(false)}
                  />
                </div>
              </SwipeableDrawer>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SignupDialog;

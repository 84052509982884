import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  completeSubscriptionStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 80,
      overflow: "auto",
    },
  },
  titleText: {
    fontWeight: 700,
    fontSize: 30,
    color: "#57BEBF",
  },
  subtitleText: {
    fontWeight: 500,
    fontSize: 20,
    color: "rgba(124, 137, 153, 1)",
  },
  formArea: {
    flex: 1,
    display: "flex",
    marginTop: 30,
    height: "100%",
  },
  buttonsArea: {
    display: "flex",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  button: {
    width: 186,
    height: 50,
    fontWeight: 700,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  submitButton: {
    width: 468,
    height: 50,
    backgroundColor: "#57BEBF",
    "&:hover": {
      backgroundColor: "#57BEBF",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  returnButton: {
    backgroundColor: "#FFF !important",
    color: "#57BEBF !important",
    marginRight: 10,
  },
  textArea: {
    width: "100%",
  },
  progressIndicator: {
    marginLeft: 20,
  },
}));

export default useStyles;

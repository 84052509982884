import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.modal,
  },
  defaultSelectButtonContainer: {
    width: "100%",
  },
  defaultSelectButton: {
    width: "100%",
    height: 60,
    borderRadius: 30,
    backgroundColor: "rgba(87, 190, 191, 1) !important",
    fontSize: theme.typography.h5.fontSize,
    color: "#FFF",
  },
  defaultSelectMenuContainer: {
    backgroundColor: "#FFF",
    width: "100%",
    maxHeight: 326,
    overflow: "auto",
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    WebkitBoxShadow: "12px 5px 15px 5px rgba(0,0,0,0.08)",
    boxShadow: "12px 5px 15px 5px rgba(0,0,0,0.08)",
  },
  defaultSelectOptionItem: {
    height: 42,
    display: "flex",
    justifyContent: "center",
    color: "rgba(124, 137, 153, 1)",
    "&:last-child": {
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
      height: 58,
    },
  },
  defaultSelectOptionText: {
    color: "rgba(124, 137, 153, 1)",
    fontFamily: "Dosis, Roboto",
    textTransform: "uppercase",
    fontWeight: 800,
  },
  selectedOptionText: {
    color: "rgba(87, 190, 191, 1)",
  },
}));

export default useStyles;

import { Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import EventTypes from "../../../constants/event-types";
import signupStepsImport from "../../../constants/signup-steps";
import ISignupStep from "../../../interfaces/signup-step";
import eventService from "../../../services/event-service";
import useStyles from "./styles";

interface ISignupProgressStepperProps {
  selectedMonth: number;
}

interface IStepItemProps {
  step: ISignupStep;
  isSelected: boolean;
  isCurrentStep: boolean;
  index: number;
  currentStep: number;
}

function StepItem(props: IStepItemProps) {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return !isMobile ||
    (((isMobile && props.isCurrentStep) ||
      (isMobile && props.index <= props.currentStep + 2)) &&
      props.index >= props.currentStep) ? (
    <div
      className={classNames(
        classes.stepItem,
        props.isCurrentStep ? classes.selectedStepItem : null
      )}
    >
      <div
        className={classes.stepNumberContainer}
        style={{
          backgroundColor: props.isSelected
            ? "#57BEBF"
            : theme.palette.text.secondary,
        }}
      >
        <Typography className={classes.stepNumberText} variant="body1">
          {props.index + 1}
        </Typography>
      </div>
      {props.isCurrentStep ? (
        <Typography className={classes.stepLabelText} variant="h6">
          {props.step.label}
        </Typography>
      ) : null}
    </div>
  ) : null;
}

function SignupProgressStepper(props: ISignupProgressStepperProps) {
  const classes = useStyles();

  const [currentStep, setCurrentStep] = useState(0);
  const [signupSteps, setSignupSteps] = useState([...signupStepsImport]);

  useEffect(() => {
    eventService.addListener(EventTypes.SIGNUP_STEP_CHANGED, (step: number) => {
      setCurrentStep(step);
    });

    eventService.addListener(EventTypes.ON_YEARLY_SUBSCRIPTION, () => {
      handleYearlySubscription();
    });

    eventService.addListener(
      EventTypes.ON_PROMOTIONAL_STEP_CHANGE,
      (display: boolean) => {
        if (display) {
          addPromotionalStep();
        } else {
          removePromotionalStep();
        }
      }
    );

    return () => {
      eventService.removeListener(EventTypes.SIGNUP_STEP_CHANGED, () => {});
      eventService.removeListener(
        EventTypes.ON_PROMOTIONAL_STEP_CHANGE,
        () => {}
      );
      eventService.removeListener(EventTypes.ON_YEARLY_SUBSCRIPTION, () => {});
    };
  }, []);

  function removePromotionalStep(): void {
    setSignupSteps(signupSteps.filter((signupStep, index) => index !== 0));
  }

  function addPromotionalStep(): void {
    setSignupSteps([...signupStepsImport]);
  }

  function handleYearlySubscription(): void {
    setSignupSteps(
      signupSteps.filter(
        (signupStep, index) =>
          signupStep.label !== "Assinatura" && signupStep.label !== "Promoção"
      )
    );
  }

  return (
    <div className={classes.signupProgressStepper}>
      {signupSteps.map((step, index) => (
        <StepItem
          step={step}
          isSelected={currentStep >= index}
          isCurrentStep={currentStep === index}
          currentStep={currentStep}
          index={index}
          key={index}
        />
      ))}
    </div>
  );
}

export default SignupProgressStepper;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    height: "15dvh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 48,
    [theme.breakpoints.down("sm")]: {
      height: "40dvh",
      textAlign: "center",
    },
  },
  progress: {
    width: "100%",
  },
}));

export default useStyles;

import { Button, Typography } from "@material-ui/core";
import { ArrowForward as RightIcon } from "@material-ui/icons";
import React from "react";
import EventTypes from "../../../../constants/event-types";
import { PaymentTypes } from "../../../../constants/payment-types";
import eventService from "../../../../services/event-service";
import useStyles from "./styles";

interface TermsAndConditionsStepProps {
  paymentType: PaymentTypes;
}

function TermsAndConditionsStep(props: TermsAndConditionsStepProps) {
  const classes = useStyles();

  function onAcceptTermsAndConditions(): void {
    eventService.emitEvent(EventTypes.TERMS_AND_CONDITIONS_CHANGED, [true]);
  }
  return (
    <div className={classes.termsAndConditionsStep}>
      <div>
        <Typography className={classes.titleText}>Oba!</Typography>
      </div>
      <div className={classes.textArea}>
        <Typography variant="h6" className={classes.paragraph}>
          Estamos felizes em poder acompanhar o seu bebê nos primeiros anos de
          vida!
        </Typography>
        <Typography variant="h6" className={classes.paragraph}>
          Na opção pela assinatura mensal, o pagamento da mensalidade é feito
          automaticamente no seu cartão, de forma que não compromete o limite
          total, e enviamos sempre após a data de pagamento. Por isso a
          assinatura não pode ser feita via cartão virtual que expira em 48h,
          certo?
        </Typography>
        <Typography variant="h6" className={classes.paragraph}>
          Caso deseje cancelar, você deve enviar um email para
          <a
            href="mailto:atendimento@smartbabybox.com.br"
            className={classes.emailText}
          >
            {" "}
            atendimento@smartbabybox.com.br
          </a>{" "}
          com 40 dias de antecedência, ok? Assim enviamos a próxima Box e
          cancelamos. Seja bem-vinda à família Smart Baby Box!
        </Typography>
      </div>
      <div className={classes.submitButtonArea}>
        <Button
          variant="contained"
          className={classes.submitButton}
          onClick={onAcceptTermsAndConditions}
          endIcon={<RightIcon />}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
}

export default TermsAndConditionsStep;

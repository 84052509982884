import AddressStep from "../components/signup/signup-form/address-step";
import BabyInfoStep from "../components/signup/signup-form/baby-info-step";
import CompleteSubscriptionStep from "../components/signup/signup-form/complete-subscription-step";
import PersonalInfoStep from "../components/signup/signup-form/personal-info-step";
import SubscriptionTypeSelectionStep from "../components/signup/signup-form/subscription-type-selection-step";
import ISignupStep from "../interfaces/signup-step";

const signupSteps: ISignupStep[] = [
  {
    label: "Assinatura",
    Component: SubscriptionTypeSelectionStep,
  },
  {
    label: "Seus dados",
    Component: PersonalInfoStep,
  },
  {
    label: "Seu bebê",
    Component: BabyInfoStep,
  },
  {
    label: "Endereço",
    Component: AddressStep,
  },
  {
    label: "Finalizar",
    Component: CompleteSubscriptionStep,
  },
];

export default signupSteps;

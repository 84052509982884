import { navigate } from "gatsby";
import PagarmeCheckout from "pagarme-checkout-react/src/index";
import { PagarmeCheckoutProps } from "pagarme-checkout-react/src/interfaces/props";
import React, { useEffect, useState } from "react";
import EventTypes from "../../../constants/event-types";
import { PaymentTypes } from "../../../constants/payment-types";
import plans, { PlanItem } from "../../../constants/plans";
import signupStepsImport from "../../../constants/signup-steps";
import { SubscriptionTypes } from "../../../constants/subscription-types";
import { SubscriptionFormParams } from "../../../interfaces/subscription";
import dataService from "../../../services/data-service/data-service";
import eventService from "../../../services/event-service";
import ProcessSubscriptionDialog from "../../process-subscription-dialog";
import SubscriptionErrorDialog from "../../subscription-error-dialog";
import { formatCreateSubscriptionRequestParams } from "./signup-form.utilities";
import useStyles from "./styles";
import TermsAndConditionsStep from "./terms-and-conditions-step";

interface ISignupFormProps {
  subscriptionCreated: Function;
  selectedSubscriptionType: SubscriptionTypes;
  selectedPaymentType: PaymentTypes;
}

function SignupForm(props: ISignupFormProps) {
  const classes = useStyles();

  const [currentStep, setCurrentStep] = useState(0);
  const [subscription, setSubscription] = useState({
    selectedBoxes: [1],
  } as SubscriptionFormParams);
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] =
    useState(false);
  const [signupSteps, setSignupSteps] = useState([...signupStepsImport]);
  const [paymentFormOpen, setPaymentFormOpen] = useState(false);
  const [isProcessingSubscription, setIsProcessingSubscription] =
    useState(false);
  const [subscriptionError, setSubscriptionError] = useState(
    null as string | null
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    eventService.addListener(
      EventTypes.TERMS_AND_CONDITIONS_CHANGED,
      (accepted: boolean) => {
        setTermsAndConditionsAccepted(accepted);
      }
    );

    return () => {
      eventService.removeListener(EventTypes.TERMS_AND_CONDITIONS_CHANGED, () =>
        setTermsAndConditionsAccepted(false)
      );
    };
  }, []);

  useEffect(() => {
    eventService.emitEvent(EventTypes.SIGNUP_STEP_CHANGED, [currentStep]);
  }, [currentStep]);

  useEffect(() => {
    if (
      props.selectedPaymentType !== PaymentTypes.YEARLY &&
      props.selectedPaymentType !== PaymentTypes.SEMIANNUAL
    ) {
      setSignupSteps([...signupStepsImport]);
    }
  }, [subscription]);

  useEffect(() => {
    setSubscription({
      ...subscription,
      type: props.selectedSubscriptionType,
    });
    if (
      props.selectedPaymentType === PaymentTypes.SEMIANNUAL ||
      props.selectedPaymentType === PaymentTypes.YEARLY
    ) {
      eventService.emitEvent(EventTypes.ON_YEARLY_SUBSCRIPTION);
      setSignupSteps(
        signupSteps.filter(
          (signupStep, index) => signupStep.label !== "Promoção"
        )
      );
    }
  }, [props]);

  let StepComponent = signupSteps.find(
    (step, index) => index === currentStep
  )?.Component;

  async function onAdvanceStep(formData: any): Promise<void> {
    setSubscription({
      ...subscription,
      ...formData,
    });
    let notUpdatedCurrentStep = currentStep;

    if (isLastStep()) {
      setPaymentFormOpen(true);
    } else {
      setCurrentStep(currentStep + 1);
    }

    if (notUpdatedCurrentStep === 1) {
      await handleSubscriptionFlowStart({ ...subscription, ...formData });
    }
  }

  async function handleSubscriptionFlowStart(
    data: SubscriptionFormParams
  ): Promise<void> {
    await dataService.subscriptionFlowStart({
      ...data,
      box: data.selectedBoxes[0],
    });
  }

  async function handleSubmit(formData: { [key: string]: any }): Promise<void> {
    setPaymentFormOpen(false);
    setIsLoading(true);
    setIsProcessingSubscription(true);
    try {
      await dataService.submitForm(
        formatCreateSubscriptionRequestParams({ ...subscription, ...formData })
      );
      setIsProcessingSubscription(false);
      navigate("/assinatura-recebida");
    } catch (error) {
      setSubscriptionError(error);
      setIsProcessingSubscription(false);
    }
    setIsLoading(false);
  }

  function isLastStep(): boolean {
    return currentStep === signupSteps.length - 1;
  }

  function onReturnStep(): void {
    if (currentStep === 0) {
      eventService.emitEvent(EventTypes.TERMS_AND_CONDITIONS_CHANGED, [false]);
    } else {
      setCurrentStep(currentStep - 1);
    }
  }

  function onCloseSubscriptionErrorDialog(): void {
    setSubscriptionError(null);
  }

  function getPagarmeCheckoutSettings(): PagarmeCheckoutProps["settings"] {
    if (!subscription.cpf) return {} as PagarmeCheckoutProps["settings"];

    const plan = plans[subscription.type] as PlanItem;
    const planValue = Math.round(plan.value * 100);
    return {
      amount: planValue,
      customerData: false,
      createToken: false,
      payment_methods: "credit_card",
      items: [
        {
          id: "assinatura-sbb",
          unit_price: planValue,
          title: `Smart Baby Box - ${plan.name}`,
          quantity: 1,
          tangible: false,
        },
      ],
    };
  }

  return (
    <>
      <div className={classes.signupForm}>
        <div className={classes.formContent}>
          {termsAndConditionsAccepted ? (
            <div className={classes.afterTermsAndConditionsStep}>
              <StepComponent
                onAdvanceStep={onAdvanceStep}
                onReturnStep={onReturnStep}
                selectedPlan={plans[props.selectedSubscriptionType]}
                selectedMonth={Math.max.apply(
                  Math,
                  subscription.selectedBoxes as any
                )}
                selectedPaymentType={props.selectedPaymentType}
                selectedSubscriptionType={props.selectedSubscriptionType}
                subscription={subscription}
                isLoading={isLoading}
              />
            </div>
          ) : (
            <TermsAndConditionsStep paymentType={props.selectedPaymentType} />
          )}
        </div>
      </div>
      <PagarmeCheckout
        encryptionKey={process.env.REACT_APP_PAGARME_ENCRYPTION_KEY as string}
        open={paymentFormOpen}
        close={() => setPaymentFormOpen(false)}
        error={(error) => console.error(error)}
        success={(data: { card_hash: string }) => {
          handleSubmit({ cardToken: data.card_hash });
        }}
        settings={getPagarmeCheckoutSettings()}
      />
      <ProcessSubscriptionDialog open={isProcessingSubscription} />
      <SubscriptionErrorDialog
        open={!!subscriptionError}
        onClose={() => onCloseSubscriptionErrorDialog()}
      />
    </>
  );
}

export default SignupForm;

import { Button, Fab, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import EventTypes from "../../../../constants/event-types";
import { PaymentTypes } from "../../../../constants/payment-types";
import {
  SubscriptionTypeLabels,
  SubscriptionTypes,
} from "../../../../constants/subscription-types";
import eventService from "../../../../services/event-service";
import DefaultSelect from "../../../default-select/DefaultSelect";
import useStyles from "./styles";
import { WhatsApp } from "@material-ui/icons";

interface SubscriptionTypeSelectionStep {
  onReturnStep: Function;
  onAdvanceStep: Function;
  selectedSubscriptionType: SubscriptionTypes;
  selectedPaymentType: PaymentTypes;
}

function SubscriptionTypeSelectionStep(props: SubscriptionTypeSelectionStep) {
  const classes = useStyles();
  const whatsAppLink = `https://wa.me/+5511953003557?text=${encodeURI(
    "Olá! Estou com dúvidas sobre a Assinatura, pode me ajudar?"
  )}`;

  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(
    props.selectedSubscriptionType as SubscriptionTypes
  );
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [availableMonths, setAvailableMonths] = useState([] as number[]);

  useEffect(() => {
    setAvailableMonths(getAvailableMonthOptions());
  }, [props.selectedSubscriptionType]);

  function onChangeSubscriptionType(newTypeLabel: string): void {
    const subscriptionTypeKey = Object.keys(SubscriptionTypeLabels).find(
      (subscriptionTypeLabel) =>
        SubscriptionTypeLabels[subscriptionTypeLabel] === newTypeLabel
    );
    setSelectedSubscriptionType(subscriptionTypeKey as any);
    if (
      subscriptionTypeKey === SubscriptionTypes.Complete &&
      selectedMonth === 3
    ) {
      onChangeSelectedMonth(1);
    }
    eventService.emitEvent(EventTypes.SELECTED_SUBSCRIPTION_TYPE_CHANGED, [
      subscriptionTypeKey,
    ]);
  }

  function onChangeSelectedMonth(month: number): void {
    setSelectedMonth(month);
    eventService.emitEvent(EventTypes.SELECTED_MONTH_CHANGED, [month]);
  }

  function getAvailableMonthOptions(): number[] {
    const months = Array.from({ length: 24 }, (_, i) => i + 1);
    if (selectedSubscriptionType === SubscriptionTypes.Complete) {
      return months.filter((month) => month !== 3);
    }
    return months;
  }

  function handleWhatsAppButtonClick(): void {
    window.location = whatsAppLink as unknown as Location;
  }

  return (
    <div className={classes.subscriptionTypeSelectionStep}>
      <div>
        <Typography className={classes.titleText}>
          Escolha sua assinatura.
        </Typography>
        <Typography className={classes.descriptionText}>
          A partir de qual mês você quer assinar?
        </Typography>
      </div>
      <div className={classes.formArea}>
        <div className={classes.selectContainer}>
          <DefaultSelect
            options={Object.values(SubscriptionTypeLabels)}
            selectedOption={SubscriptionTypeLabels[selectedSubscriptionType]}
            onSelectOption={(selectedOption: string) =>
              onChangeSubscriptionType(selectedOption)
            }
          />
          <DefaultSelect
            options={availableMonths}
            selectedOption={selectedMonth}
            onSelectOption={(selectedMonth: number) =>
              onChangeSelectedMonth(selectedMonth)
            }
            labelComplement="A partir do mês"
          />
        </div>
      </div>
      <Fab
        className={classes.whatsAppButton}
        size="large"
        onClick={() => handleWhatsAppButtonClick()}
      >
        <WhatsApp />
      </Fab>
      <div className={classes.buttonsArea}>
        <Button
          variant="contained"
          className={classNames(classes.button, classes.returnButton)}
          onClick={() => props.onReturnStep()}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          className={classNames(classes.button, classes.nextButton)}
          onClick={() =>
            props.onAdvanceStep({
              type: selectedSubscriptionType,
              selectedBoxes: [selectedMonth],
            })
          }
        >
          Continuar
        </Button>
      </div>
    </div>
  );
}

export default SubscriptionTypeSelectionStep;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  signupForm: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  afterTermsAndConditionsStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: 30,
      maxHeight: "calc(100vh - 58px)",
      overflow: "hidden",
    },
  },
  formContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    paddingBottom: 80,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
    },
  },
}));

export default useStyles;

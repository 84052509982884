import basicBoxImage from "../assets/images/basic-box-image.png";
import completeBoxImage from "../assets/images/complete-box-image.png";
import { SubscriptionTypes } from "./subscription-types";

interface PlanValue {
  subscription: number;
}
export type PlanItemMonth = {
  title?: string;
  items: string[];
  value?: PlanValue;
  month: number;
};

export type PlanItem = {
  name: string;
  type: string;
  boxName: string;
  value: number;
  highlighted?: boolean;
  boxImage: string;
  months: PlanItemMonth[];
};

const plans = {
  [SubscriptionTypes.Basic]: {
    name: "Assinatura básica",
    boxName: "básica",
    type: SubscriptionTypes.Basic,
    value: 205.16,
    boxImage: basicBoxImage,
    months: [
      {
        items: [
          "Cavalete Babygym Montessori",
          "Móbile Montessori Munari para bebê",
        ],
        value: {
          subscription: 205.16,
        },

        month: 1,
      },
      {
        items: [
          "Móbile Octaedro",
          "Móbile Montessori Gobbi",
          "Móbile Dançarinos",
        ],
        value: {
          subscription: 205.16,
        },

        month: 2,
      },
      {
        items: ["Móbile Tátil", "Móbile Sonoro", "Pipa de Mão Waldorf"],
        value: {
          subscription: 205.16,
        },

        month: 3,
      },
      {
        items: [
          "Chocalho com Bolas",
          "Chocalho de Argolas",
          "Discos Interconectados",
          "Mordedor em Crochê",
        ],
        value: {
          subscription: 205.16,
        },
        month: 4,
      },
      {
        items: ["Mapa Sensorial "],
        value: {
          subscription: 205.16,
        },
        month: 5,
      },
      {
        items: ["Bola Montessori em Crochê", "Cesto de Descobertas"],
        value: {
          subscription: 205.16,
        },
        month: 6,
      },
      {
        items: ["Caixa de Permanência", "Bola Caixa de Permanência 40mm"],
        value: {
          subscription: 205.16,
        },
        month: 7,
      },
      {
        items: ["Barra de Apoio", "Maraca Indígena"],
        value: {
          subscription: 205.16,
        },
        month: 8,
      },
      {
        items: ["Kit de 9 Cubos com Bandeja"],
        value: {
          subscription: 205.16,
        },
        month: 9,
      },
      {
        items: ["Torre de Encaixe"],
        value: {
          subscription: 205.16,
        },
        month: 10,
      },
      {
        items: ["Livro Sensorial para Bebês Quiet Book"],
        value: {
          subscription: 205.16,
        },
        month: 11,
      },
      {
        items: ["Chocalho Andador"],
        value: {
          subscription: 205.16,
        },
        month: 12,
      },
      {
        month: 13,
        items: ["Kit 2 animais sortidos", "Cards de Pareamento 2 Animais"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 14,
        items: ["Kit Frutas em Madeira"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 15,
        items: ["Cesto de Compras ou Moisés de Madeira"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 16,
        items: ["Kit de Panelas em Madeira"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 17,
        items: ["Kit 7 Cubos Waldorf"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 18,
        items: ["Kit de Carrinhos de Madeira Brinquedo Montessori"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 19,
        items: ["Kit de Blocos com 10 peças"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 20,
        items: ["Carriola de Puxar"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 21,
        items: ["Quebra-Cabeças Sol e Lua"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 22,
        items: ["Kit de Utensílios para os Primeiros Experimentos"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 23,
        items: ["Arco-Íris Waldorf de Madeira", "Cards emoções"],
        value: {
          subscription: 205.16,
        },
      },
      {
        month: 24,
        items: ["Banqueta Montessori"],
        value: {
          subscription: 205.16,
        },
      },
    ],
  },
  [SubscriptionTypes.Complete]: {
    name: "Assinatura completa",
    boxName: "completa",
    type: SubscriptionTypes.Complete,
    value: 274.4,
    boxImage: completeBoxImage,
    months: [
      {
        items: [
          "Cavalete Babygym Montessori",
          "Tapete para Recém-nascido",
          "Móbile Montessori Munari para bebê",
        ],
        value: {
          subscription: 274.4,
        },
        month: 1,
      },
      {
        items: [
          "Móbile Octaedro",
          "Móbile Montessori Gobbi",
          "Móbile Dançarinos",
        ],
        value: {
          subscription: 274.4,
        },
        month: 2,
      },
      {
        items: [
          "Espelho Montessori em Acrílico",
          "Móbile Tátil",
          "Móbile Sonoro",
          "Pipa de Mão Waldorf",
        ],
        value: {
          subscription: 274.4,
        },
        month: 3,
      },
      {
        items: [
          "Chocalho com Bolas",
          "Chocalho de Argolas",
          "Discos Interconectados",
          "Mordedor em Crochê",
        ],
        value: {
          subscription: 274.4,
        },
        month: 4,
      },
      {
        items: ["Mapa Sensorial", "Tatames"],
        value: {
          subscription: 274.4,
        },
        month: 5,
      },
      {
        items: ["Bola Montessori em Crochê", "Cesto de Descobertas "],
        value: {
          subscription: 274.4,
        },
        month: 6,
      },
      {
        items: ["Caixa de Permanência", "Bola Caixa de Permanência 40mm"],
        value: {
          subscription: 274.4,
        },
        month: 7,
      },
      {
        items: ["Barra de Apoio", "Maraca Indígena"],
        value: {
          subscription: 274.4,
        },
        month: 8,
      },
      {
        items: ["Kit de 9 Cubos com Bandeja", "Kit de Cilindros Coloridos"],
        value: {
          subscription: 274.4,
        },
        month: 9,
      },
      {
        items: ["Torre de Encaixe"],
        value: {
          subscription: 274.4,
        },
        month: 10,
      },
      {
        items: ["Painel Sensorial"],
        value: {
          subscription: 274.4,
        },
        month: 11,
      },
      {
        items: ["Motoca de Equilíbrio"],
        value: {
          subscription: 274.4,
        },
        month: 12,
      },
      {
        month: 13,
        items: [
          "Kit 4 animais: Leão, Rinoceronte, Girafa e Elefante",
          "Cards de Pareamento",
        ],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 14,
        items: ["Frutas em crochê", "Cards de Pareamento Frutas"],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 15,
        items: ["Carrinho de Bebê e Compras em Madeira"],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 16,
        items: ["Kit de Panelas em Madeira"],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 17,
        items: ["Kit 7 Cubos Waldorf"],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 18,
        items: [
          "Kit de Carrinhos de Madeira Brinquedo Montessori",
          "Tecido Alinhavo",
        ],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 19,
        items: ["Kit de Blocos com 20 peças"],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 20,
        items: [
          "Carriola de Puxar",
          "Kit 7 Bolas Coloridas",
          "Kit 7 Argolas Coloridas",
        ],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 21,
        items: ["Quebra-Cabeças Sol e Lua", "Quebra-Cabeças Árvore"],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 22,
        items: [
          "Kit de Utensílios para os Primeiros Experimentos",
          "Kit de Ingredientes para os Primeiros Experimentos",
        ],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 23,
        items: ["Arco-Íris Waldorf de Madeira", "Cards emoções"],
        value: {
          subscription: 274.4,
        },
      },
      {
        month: 24,
        items: ["Espelho do Auto-Cuidado Montessori"],
        value: {
          subscription: 274.4,
        },
      },
    ],
  },
} as const satisfies Record<SubscriptionTypes, PlanItem>;

export default plans;

import { ISetting } from "../../interfaces/setting";
import {
  CreateSubscriptionRequest,
  SubscriptionFlowStartParams,
  SubscriptionFormParams,
} from "../../interfaces/subscription";

class DataService {
  async submitForm(data: CreateSubscriptionRequest): Promise<void> {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/subscriptions`,
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();

    if (response.status === 500) {
      throw Error(result);
    }
  }

  public async subscriptionFlowStart(
    data: SubscriptionFlowStartParams
  ): Promise<void> {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/subscriptions/flow-start`,
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );
  }

  public async getSettings(): Promise<ISetting[]> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/settings`
      );
      return await response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new DataService();

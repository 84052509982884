import { Button, TextField, Typography } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { areAllValuesFilled } from "../../../../helpers/form-helper/form-helper";
import yupValidations from "../../../../helpers/yup-validations";
import InputMask from "react-input-mask";
import classNames from "classnames";

interface IBabyInfoStepProps {
  onAdvanceStep: Function;
  onReturnStep: Function;
  subscription: any;
}

function MaskedBabyBirthDateInput(props) {
  const classes = useStyles();
  return (
    <InputMask
      mask="99/99/9999"
      value={props.value}
      onChange={props.onChange}
      maskPlaceholder=""
      onBlur={() => props.validateField("babyBirthDate")}
    >
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Data de nascimento do bebê"
        className={classes.textInput}
        error={Boolean(props.errors.babyBirthDate)}
        helperText={props.errors.babyBirthDate}
      />
    </InputMask>
  );
}

function BabyInfoStep(props: IBabyInfoStepProps) {
  const classes = useStyles();

  const babyInfoStepInitialValues = {
    babyName: props.subscription.babyName || "",
    babyBirthDate: props.subscription.babyBirthDate || "",
  };

  const babyInfoStepFormSchema = Yup.object().shape({
    babyName: Yup.string().required("Preencha o nome do bebê."),
    babyBirthDate: yupValidations.babyBirthDate,
  });

  return (
    <div className={classes.babyInfoStep}>
      <Typography className={classes.titleText}>
        Preencha os campos abaixo.
      </Typography>
      <div>
        <Typography variant="h5" className={classes.stepInfoText}>
          Caso esteja grávida, coloque a sua DPP!
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <Formik
          initialValues={babyInfoStepInitialValues}
          validationSchema={babyInfoStepFormSchema}
          onSubmit={(values) => props.onAdvanceStep(values)}
          validateOnChange={false}
          validateOnBlur={true}
          isInitialValid={false}
        >
          {({
            submitForm,
            errors,
            values,
            setFieldValue,
            validateField,
            isValid,
            dirty,
          }) => {
            if (!dirty && areAllValuesFilled(values)) {
              isValid = true;
            }

            function handleInputChange(field: string, value: string): void {
              setFieldValue(field, value);
            }

            return (
              <Form className={classes.form}>
                <div className={classes.formArea}>
                  <TextField
                    fullWidth
                    autoFocus
                    variant="outlined"
                    placeholder="Nome do bebê"
                    className={classes.textInput}
                    error={Boolean(errors.babyName)}
                    helperText={errors.babyName}
                    value={values.babyName}
                    onChange={(event) =>
                      handleInputChange("babyName", event.target.value)
                    }
                    onBlur={() => validateField("babyName")}
                  />
                  <MaskedBabyBirthDateInput
                    onChange={(event) =>
                      handleInputChange("babyBirthDate", event.target.value)
                    }
                    value={values.babyBirthDate}
                    errors={errors}
                    validateField={validateField}
                  />
                </div>
                <div className={classes.submitButtonArea}>
                  <Button
                    variant="contained"
                    onClick={() => props.onReturnStep()}
                    className={classNames(classes.button, classes.returnButton)}
                  >
                    Voltar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={submitForm}
                    disabled={!isValid || !areAllValuesFilled(values)}
                    className={classNames(classes.button, classes.submitButton)}
                  >
                    Continuar
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default BabyInfoStep;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  titleText: {
    fontWeight: 700,
    color: "rgba(87, 190, 191, 1)",
    fontSize: 36,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      color: "#FFF",
    },
  },
  termsAndConditionsStep: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "rgba(87, 190, 191, 1)",
      padding: 30,
      maxHeight: "100dvh",
    },
  },
  textArea: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
  },
  paragraph: {
    marginTop: 30,
    color: "#7C8999",
    fontWeight: 500,
    fontFamily: "Roboto",
    [theme.breakpoints.down("sm")]: {
      color: "#FFF",
    },
  },
  submitButtonArea: {
    marginTop: 30,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  submitButton: {
    width: 272,
    height: 50,
    backgroundColor: "rgba(87, 190, 191, 1) !important",
    fontWeight: 700,
    fontSize: 24,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFF !important",
      color: "rgba(87, 190, 191, 1) ",
    },
  },
  emailText: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
}));

export default useStyles;
